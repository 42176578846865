@font-face
{
    font-family: "Raleway";
    src: url(https://cdn.glitch.com/6a30ec1b-c3fa-45e5-988d-c25b19681527%2FRaleway-ExtraLight.ttf?v=1583769637102);
    font-weight: lighter;
    font-style: normal;
}
@font-face 
{
    font-family: "Raleway";
    src: url(https://cdn.glitch.com/6a30ec1b-c3fa-45e5-988d-c25b19681527%2FRaleway-SemiBold.ttf?v=1583769786852);
    font-weight: bold;
    font-style: normal;
}
body
{
    background-image: url(https://cdn.glitch.com/6a30ec1b-c3fa-45e5-988d-c25b19681527%2Fbackground.jpg?v=1583769472311);
    background-repeat: no-repeat;
    background-attachment: fixed;
    background-size: cover;
    color: white;
    font-family: "Raleway", Arial, sans-serif;
    font-size: 1em;
    font-weight: bold;
    min-height: 500px;
}

#merci
{
  font-weight: bold;
  font-size: 100px;
  text-align: center;
  margin-top: 30%;
}

#titre
{
    margin-top: 40px;
}

#titre1
{
    margin-bottom: 40px;
}

.navbar
{
    margin: 0;
    padding: 0;
    background-color: black;
    box-shadow: 0px 0px 100px rgba(253, 245, 245, 1);
}
.navbar-dark .navbar-nav .nav-link
{
    color: white;
    padding: 10px 10px;
    width: 150px;
    display: inline-block;
}
.navbar-nav
{
    text-align: center;
    width: 100%;
    /*justify-content: flex-end;*/
}

footer
{
  margin-top: 50px;
  text-align: center;
  background-color: black;
  font-family: sans-serif;
  font-weight: lighter;
  font-size: 0.8em;
  padding: 15.5px;
  align-content: center;
}
/************************************************************* ok ***************************************************/

